
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import shape2 from "../images/shape-2.png"
import shape3 from "../images/shape-3.png"
import shape3P500 from "../images/shape-3-p-500.png"
import shape3P800 from "../images/shape-3-p-800.png"
import shape3P1080 from "../images/shape-3-p-1080.png"

  const ContactView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div id="Top" className="hero about _4">
    <div className="container">
      <div className="hero-grid">
        <div>
          <h1 className="display-1"><Trans>Le plus important ?&nbsp;</Trans><br /><Trans>L'écoute.</Trans></h1>
          <div className="top-margin-30px">
            <p className="sub-text"><Trans>Nous concevons des solutions sur-mesure</Trans></p>
          </div>
        </div>
      </div>
    </div><img src={shape2} loading="lazy" width={60} alt="shape2" className="scribble-1 sub" /><img src={shape3} loading="lazy" width={80} sizes="(max-width: 767px) 100vw, (max-width: 991px) 70px, 100vw" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2 sub" />
  </div>
  <div id="Team" className="section _0px-top wf-section">
    <div className="container">
      <h1 className="heading-3"><Trans>Contact</Trans></h1>
      <div className="info-grid">
        <div id="w-node-cf019753-a689-1d60-b996-cfc003b51c3a-90ba6394" className="info-wrapper">
          <div className="badge yellow"><Trans>Nous envoyer un email</Trans></div>
          <p className="info-paragraph">contact@interstellar.run</p>
        </div>
        <div className="info-wrapper" />
        <div className="info-wrapper">
          <div className="badge yellow"><Trans>Nous appeler</Trans></div>
          <p className="info-paragraph"><Trans>06&nbsp;69&nbsp;31&nbsp;97&nbsp;96</Trans></p>
        </div>
      </div>
      <div className="top-margin-100px">
        <div id="Contact-Form" className="form-block w-form">
          <form id="Contact-Form" name="Contact-Form" data-name="Contact-Form" data-netlify="true" netlify-honeypot="bot-field" onSubmit={props.submitContactForm}>
            <div className="form-grid">
              <div className="div-block-6"><Trans>{props.hiddenTags}</Trans></div>
              <div><label htmlFor="Name-4" className="field-label-form"><Trans>Nom</Trans><span className="color">*</span></label><input type="text" className="text-field-primary w-input" maxLength={256} name="Name" data-name="Name" placeholder={t("Votre nom")} id="Name-4" required /></div>
              <div><label className="field-label-form"><Trans>Entreprise</Trans></label><input type="text" className="text-field-primary w-input" maxLength={256} name="Company" data-name="Company" placeholder={t("Nom de l'entreprise")} id="Company" /></div>
              <div><label htmlFor="Email" className="field-label-form"><Trans>Email</Trans><span className="color">*</span></label><input type="email" className="text-field-primary w-input" maxLength={256} name="Email" data-name="Email" placeholder={t("Votre email")} id="Email" required /></div>
              <div><label htmlFor="Email" className="field-label-form"><Trans>Categorie</Trans><span className="color">*</span></label>
                <div className="text-field-primary"><select id="Category" name="Category" required data-name="Category" className="select-field w-select">
                    <option value="">{t("Choix")}</option>
                    <option value="vitrine">{t("Vitrine")}</option>
                    <option value="ecommerce">{t("E-commerce")}</option>
                    <option value="unique">{t("Unique")}</option>
                  </select></div>
              </div>
              <div><label htmlFor="Email" className="field-label-form"><Trans>Téléphone</Trans></label><input type="tel" className="text-field-primary w-input" maxLength={256} name="Phone Number" data-name="Phone Number" placeholder={t("Votre numéro de téléphone")} id="Phone-Number" required /></div>
            </div>
            <div className="top-margin-20px"><label htmlFor="Description" className="field-label-form"><Trans>détail du projet</Trans><span className="color">*</span></label><textarea placeholder={t("Une bref description de vos attentes, vos envies...")} maxLength={5000} id="Description" name="Description" data-name="Description" required className="text-field-primary area w-input"  /></div>
            <div className="top-margin-30px">
              <div className="align-center"><input type="submit" data-wait="Please wait..." className="primary-button green contact-button-form w-button" value={t("Envoyer")} /></div>
            </div>
          </form>
          <div className="success-message w-form-done" style={{display: props.isSuccess ? "block" : "none"}}>
            <div><Trans>Merci ! Nous vous répondrons très prochainement</Trans></div>
          </div>
          <div className="error-message w-form-fail" style={{display: props.isError ? "block" : "none"}}>
            <div><Trans>Oops ! Une erreur est survenue, merci de recommencer</Trans></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>)
  };
  
  export default ContactView
  